import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import axios from "axios";

const DNSPage: React.FC = () => {
  const [user, setUser] = useState<any>({});
  const [storeDNS, setStoreDNS] = useState<string>("");
  const [response, setResponse] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get(
          "https://api.strategyfox.in/api/v1/auth/me",
          {
            withCredentials: true,
          }
        );

        if (res.status === 200) {
          setIsAuthenticated(true);
          setUser(res.data.user);
        }
      } catch (err) {
        if (err.response?.status === 401) {
          // Attempt to refresh the access token
          try {
            const refreshRes = await axios.post(
              "https://api.strategyfox.in/api/v1/auth/refresh",
              {},
              { withCredentials: true }
            );

            if (refreshRes.status === 200) {
              // Retry fetching the user data after refreshing the token
              await fetchUser();
            } else {
              // Redirect if refresh token is invalid/expired
              navigate("/gamefox/login");
            }
          } catch (refreshErr) {
            // Redirect if refresh token is invalid/expired
            navigate("/gamefox/login");
          }
        } else {
          // Redirect for other errors
          navigate("/gamefox/login");
        }
      }
    };

    fetchUser();
  }, []);

  const handleStoreDNSChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStoreDNS(event.target.value);
  };

  const handleSubmitStoreDNS = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!storeDNS) {
      setError("Subdomain is required.");
      return;
    }

    setError(null);

    try {
      const res = await axios.post(
        "https://api.strategyfox.in/api/v1/auth/me/dns",
        { subdomain: storeDNS, object: "example/index.html" },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      if (res.status === 201) {
        setResponse(`Success: ${res.data}`);
      }
    } catch (err: any) {
      setError(err.response?.data?.message || "An unexpected error occurred.");
    }
  };

  if (!isAuthenticated) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1>Hello {user.username}</h1>

      <div>
        <form onSubmit={handleSubmitStoreDNS}>
          <div>
            <label>Store DNS:</label>
            <input
              type="text"
              value={storeDNS}
              onChange={handleStoreDNSChange}
              placeholder="Enter Store DNS"
            />
          </div>
          <button type="submit">Create Store DNS</button>
        </form>
      </div>

      {response && <p>{response}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default DNSPage;
