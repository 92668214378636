import { css } from "emotion";

const LoginStyle = () => css`
  background:rgb(234, 254, 228);

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }

  .formLogin {
    background: #fff;
    border: 1px solid #4ad0cc;
    padding: 25px;
    border-radius: 11px;
    min-width: 500px;
  }

  .formLogin > h2 {
    margin-bottom: 28px;
    color: #4ad0cc;
    text-align: center;
  }

  .formLogin > div {
    margin-bottom: 15px;
  }

  .formLogin > div > div {
    position: relative;
  }

  .formLogin input[type="text"],
  .formLogin input[type="email"],
  .formLogin input[type="password"] {
    color: black;
    background: #fff;
    border-radius: 4px;
    outline: 0;
    border: 1px solid #4ad0cc;
    padding: 15px 32px;
    width: 100%;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
  .formLogin input[type="text"]:focus,
  .formLogin input[type="email"]:focus,
  .formLogin input[type="password"]:focus {
    border: 1px solid #4ad0cc;
  }

  .formLogin > div > div img {
    position: absolute;
    max-width: 20px;
    max-height: 20px;
    left: 7px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }

  .formLogin ::placeholder {
    color: #bfbbbb;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .formLogin button[type="submit"] {
    display: block;
    text-align: center;
    width: 100%;
    border-radius: 8px;
    outline: 0;
    border: none;
    color: #fff;
    padding: 10px;
    margin: 15px 0;
    background: #4ad0cc;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }

  .formLogin button[type="submit"]:hover {
    background: #52b1ae;
    cursor: pointer;
  }

  @media (max-width: 568px) {
    .formLogin {
      min-width: 300px;
      padding: 16px;
    }
    .formLogin input[type="text"],
    .formLoginQ input[type="email"],
    .formLogin input[type="password"] {
      padding: 10px 32px;
    }
  }
`;

export default LoginStyle;
