exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-gamefox-dns-tsx": () => import("./../../../src/pages/gamefox/dns.tsx" /* webpackChunkName: "component---src-pages-gamefox-dns-tsx" */),
  "component---src-pages-gamefox-index-tsx": () => import("./../../../src/pages/gamefox/index.tsx" /* webpackChunkName: "component---src-pages-gamefox-index-tsx" */),
  "component---src-pages-gamefox-login-style-ts": () => import("./../../../src/pages/gamefox/login.style.ts" /* webpackChunkName: "component---src-pages-gamefox-login-style-ts" */),
  "component---src-pages-gamefox-login-tsx": () => import("./../../../src/pages/gamefox/login.tsx" /* webpackChunkName: "component---src-pages-gamefox-login-tsx" */),
  "component---src-pages-gamefox-register-tsx": () => import("./../../../src/pages/gamefox/register.tsx" /* webpackChunkName: "component---src-pages-gamefox-register-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

