import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import axios from "axios";
import LoginStyle from "./login.style";

interface SignUpData {
  username: string;
  password: string;
}

const SignUp: React.FC = () => {
  const [data, setData] = useState<SignUpData>({
    username: "",
    password: "",
  });

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://api.strategyfox.in/api/v1/auth/register",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        navigate("/gamefox/login");
      }
    } catch (error) {
      console.log("An error occurred while registering", "error");
    }
  };

  return (
    <div className={LoginStyle()}>
      <div className="container">
        <form className="formLogin" onSubmit={submitHandler} autoComplete="off">
          <h2>REGISTER</h2>
          <div>
            <div>
              <input
                type="text"
                name="username" // This should match the state property
                value={data.username}
                placeholder="Username"
                onChange={changeHandler} // Link the changeHandler
                autoComplete="off"
              />
            </div>
          </div>
          <div>
            <div>
              <input
                type="password"
                name="password"
                value={data.password}
                placeholder="Password"
                onChange={changeHandler}
                autoComplete="off"
              />
            </div>
          </div>
          <div>
            <button type="submit">Create Account</button>
            <span
              style={{
                color: "#a29494",
                textAlign: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              Already have an account? <Link to="/gamefox/login">Login</Link>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
