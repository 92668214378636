import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import axios from "axios";

const ShopifyProductPage: React.FC = () => {
  const [user, setUser] = useState<any>({});
  const [productId, setProductId] = useState<string>("");
  const [response, setResponse] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isShopifyConnected, setIsShopifyConnected] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<string>("");
  const [apiSecret, setApiSecret] = useState<string>("");
  const [adminAccessToken, setAdminAccessToken] = useState<string>("");
  const [shopName, setShopName] = useState<string>("");
  const [scopes, setScopes] = useState<string>("");

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get(
          "https://api.strategyfox.in/api/v1/auth/me",
          {
            withCredentials: true,
          }
        );

        if (res.status === 200) {
          setIsAuthenticated(true);
          setUser(res.data.user);

          // Check if Shopify is already linked
          if (res.data.is_shopify_connected) {
            setIsShopifyConnected(true);
          }
        }
      } catch (err) {
        if (err.response?.status === 401) {
          // Attempt to refresh the access token
          try {
            const refreshRes = await axios.post(
              "https://api.strategyfox.in/api/v1/auth/refresh",
              {},
              { withCredentials: true }
            );

            if (refreshRes.status === 200) {
              // Retry fetching the user data after refreshing the token
              await fetchUser();
            } else {
              // Redirect if refresh token is invalid/expired
              navigate("/gamefox/login");
            }
          } catch (refreshErr) {
            // Redirect if refresh token is invalid/expired
            navigate("/gamefox/login");
          }
        } else {
          // Redirect for other errors
          navigate("/gamefox/login");
        }
      }
    };

    fetchUser();
  }, []);

  const handleProductIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProductId(event.target.value);
  };

  const handleApiKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApiKey(event.target.value);
  };

  const handleApiSecretChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setApiSecret(event.target.value);
  };

  const handleAdminAccessTokenChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdminAccessToken(event.target.value);
  };

  const handleShopNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShopName(event.target.value);
  };

  const handleScopesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScopes(event.target.value);
  };

  const handleSubmitShopifyLink = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!apiKey || !apiSecret || !adminAccessToken || !shopName || !scopes) {
      setError("All fields are required.");
      return;
    }

    setError(null);

    try {
      const res = await axios.post(
        "https://api.strategyfox.in/api/v1/auth/me/shopify",
        {
          api_key: apiKey,
          api_secret: apiSecret,
          admin_token: adminAccessToken,
          shop_name: shopName,
          scope: scopes,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (res.status === 200) {
        setIsShopifyConnected(true);
      }
    } catch (err: any) {
      setError(err.response?.data?.message || "An unexpected error occurred.");
    }
  };

  const handleSubmitProductId = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!productId) {
      setError("Product ID is required.");
      return;
    }

    setError(null);

    try {
      const res = await axios.post(
        "https://api.strategyfox.in/api/v1/auth/me/shopify/product/compress",
        { product_id: productId },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      if (res.status === 201) {
        setResponse(`Success: ${res.data}`);
      }
    } catch (err: any) {
      setError(err.response?.data?.message || "An unexpected error occurred.");
    }
  };

  if (!isAuthenticated) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1>Hello {user.username}</h1>

      {isShopifyConnected ? (
        <div>
          <h2>Shopify linked successfully</h2>
          <form onSubmit={handleSubmitProductId}>
            <div>
              <label>Product ID:</label>
              <input
                type="text"
                value={productId}
                onChange={handleProductIdChange}
                placeholder="Enter Product ID"
              />
            </div>
            <button type="submit">Submit Product ID</button>
          </form>
        </div>
      ) : (
        <div>
          <h2>Link Shopify Account</h2>
          <form onSubmit={handleSubmitShopifyLink}>
            <div>
              <label>API Key:</label>
              <input
                type="text"
                value={apiKey}
                onChange={handleApiKeyChange}
                placeholder="Enter API Key"
              />
            </div>
            <div>
              <label>API Secret:</label>
              <input
                type="text"
                value={apiSecret}
                onChange={handleApiSecretChange}
                placeholder="Enter API Secret"
              />
            </div>
            <div>
              <label>Admin Access Token:</label>
              <input
                type="text"
                value={adminAccessToken}
                onChange={handleAdminAccessTokenChange}
                placeholder="Enter Admin Access Token"
              />
            </div>
            <div>
              <label>Shop Name:</label>
              <input
                type="text"
                value={shopName}
                onChange={handleShopNameChange}
                placeholder="Enter Shop Name"
              />
            </div>
            <div>
              <label>Scopes:</label>
              <input
                type="text"
                value={scopes}
                onChange={handleScopesChange}
                placeholder="Enter Scopes"
              />
            </div>
            <button type="submit">Link Shopify Account</button>
          </form>
        </div>
      )}

      {response && <p>{response}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default ShopifyProductPage;
