import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import LoginStyle from "./login.style";
import axios from "axios";

interface SignInData {
  username: string;
  password: string;
}

const SignIn: React.FC = () => {
  const [data, setData] = useState<SignInData>({
    username: "",
    password: "",
  });

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://api.strategyfox.in/api/v1/auth/login",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        const accessToken = response.data["access_token"];
        const refreshToken = response.data["refresh_token"];

        document.cookie = `access-token=${accessToken}; domain=strategyfox.in; path=/; secure; samesite=None; max-age=3600`;
        document.cookie = `refresh-token=${refreshToken}; domain=strategyfox.in; path=/; secure; samesite=None; max-age=604800`;

        navigate("/gamefox");
      } else {
        console.log(
          "You have already registered, log in to your account",
          "warning"
        );
      }
    } catch (error) {
      console.log("An error occurred while registering", "error");
    }
  };

  return (
    <div className={LoginStyle()}>
      <div className="container">
        <form className="formLogin" onSubmit={submitHandler} autoComplete="off">
          <h2>LOGIN</h2>
          <div>
            <div>
              <input
                type="text"
                name="username" // This should match the state property
                value={data.username}
                placeholder="Username"
                onChange={changeHandler} // Link the changeHandler
                autoComplete="off"
              />
            </div>
          </div>
          <div>
            <div>
              <input
                type="password"
                name="password"
                value={data.password}
                placeholder="Password"
                onChange={changeHandler}
                autoComplete="off"
              />
            </div>
          </div>
          <div>
            <button type="submit">Login</button>
            <span
              style={{
                color: "#a29494",
                textAlign: "center",
                display: "inline-block",
                width: "100%",
              }}
            >
              Don't have a account?{" "}
              <Link to="/gamefox/register">Create account</Link>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
